import * as React from 'react';

import { 
  MainLayout, 
  Activities, 
  RecentArticles, 
  DailyIcon, 
  Seo 
} from '../components';


const ActivitiesPage = () => {

  const pageTitle = 'Activități';

  return (
    <>
      <Seo title={pageTitle} />
      <MainLayout title={pageTitle}>
        <article>
          <Activities />
        </article>
        <aside>
          <DailyIcon />
          <RecentArticles />
        </aside>
      </MainLayout>
    </>
  );
};

export default ActivitiesPage;

